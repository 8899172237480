.blue-print {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8));
    min-height: 888.67px;
    position: relative;
    border: 0.1px solid black;


    .background-circle-pink {

        width: 752px;
        height: 752px;

        position: absolute;
        left: 595px;
        top: 560px;
        background: #D32E9B;
        opacity: 0.3;
        filter: blur(553px);
        border: 2px solid white;

        @media(max-width: 576px) {
            left: 100px;
            top: 160px;

        }
    }

    .background-circle-blue {
        position: absolute;
        left: 118px;
        top: 1354px;
        width: 752px;
        height: 752px;
        background: #2E35D3;
        opacity: 0.3;
        filter: blur(553px);
        position: absolute;
        border: 10px solid green;

        @media(max-width:576px) {
            left: -50px;
        }
    }

    .heading {

        display: flex;
        align-items: center;
        padding-left: 80px;

        z-index: 10;
        margin-top: 61px;

        @media(max-width:768px) {
            margin-top: 39px;
            padding-left: 24px;
        }

        .heading-name {
            font-family: 'Primal';
            font-style: normal;
            font-weight: 400;
            font-size: 80px;
            line-height: 92px;
            color: #FFFFFF;

            min-width: 830px;

            @media(max-width:768px) {
                font-weight: 400;
                font-size: 32px;
                line-height: 37px;
                min-width: 312px;
            }
        }

        .dotted-line {

            position: relative;
            left: -100px;

            @media(max-width:768px) {

                left: 0px;

            }
        }


    }

    .main-container {



        padding-left: 80px;
        padding-right: 146px;
        margin-top: 50px;
        position: relative;

        @media(max-width:768px) {
            padding-left: 24px;
        }

        .main-container-line {
            position: absolute;
            left: 0px;
            top: -16px;
            width: 732px;

            @media(max-width: 768px) {
                width: 301px;
            }
        }

        .line-text {
            font-family: 'Euclid Circular A', sans-serif;
            font-style: normal;
            font-weight: 500;
            font-size: 24px;
            line-height: 150%;
            color: #BDBBBB;

            margin-bottom: 42px;

            .the-blueprint {
                color: #BDBBBB !important;
            }

            span {
                color: rgba(251, 214, 96, 1);
                font-family: 'Code Next', sans-serif;
                font-weight: 700;
            }

            @media(max-width:768px) {
                font-weight: 500;
                font-size: 12px;
                line-height: 15px;
            }
        }

        .line-pair {

            position: relative;


            .icons-line {
                width: 416px;
                height: 0px;
            }

            :nth-child(1) {
                width: 100%;
                height: 0px;
            }

            :nth-child(2) {
                transform: rotate(90deg);
                position: absolute;
                width: 70%;
                height: 0px;

            }
        }

        .blue-print-cards-container {


            display: grid;
            grid-template-columns: auto;




            .cards-section-1 {
                display: flex;

                justify-content: space-between;
                align-items: baseline;

            }

            .cards-section-2 {
                display: flex;



            }

            .cards-section-3 {
                display: flex;
                justify-content: flex-end;



            }

            .cards-section-4 {
                display: flex;



            }

            .line-pair-1 {

                top: 25%;
                width: 100%;

                :nth-child(2) {
                    right: -60px;
                    width: 40%;

                }


            }

            .line-pair-2 {


                transform: rotateX(180deg);
                top: -50%;
                width: 40%;

                :nth-child(2) {
                    right: -60px;
                    top: 20%;
                    width: 40%;

                }

            }

            .line-pair-3 {

                transform: rotate(180deg);
                width: 40%;
                top: -50%;

                :nth-child(2) {
                    right: -60px;
                    top: 20%;
                }



            }

            .line-pair-4 {
                transform: rotateX(180deg);
                top: -50%;
                width: 60%;

                :nth-child(2) {
                    right: -100px;
                    top: 20%;
                    width: 40%;
                }

            }



        }

        .bpc-desktop {
            @media(max-width: 1200px) {
                display: none;
            }
        }

        .bpc-mobile {
            display: none;

            @media(max-width: 1200px) {
                display: flex;
                flex-direction: column;
                max-width: 437px;
            }

            @media(max-width:768px) {
                min-width: 342px;
                width: 342px;
            }

            @media (max-width:400px) {
                min-width: 300px;
                width: 300px;
            }

            .line-pair-mobile {

                height: 68px;
                position: relative;


                :nth-child(1) {
                    width: 221px;
                    position: relative;
                    top: 50%;
                }

                :nth-child(2) {
                    transform: rotate(90deg);
                    position: absolute;
                    width: 120px;
                    right: 75%;
                }
            }

            .lpm-2 {


                padding-left: 49%;

                @media(max-width:768px) {
                    padding-left: 35%;
                }

                @media(max-width:400px) {
                    padding-left: 26%;
                }

                :nth-child(2) {
                    left: 75%;
                }
            }

            .lpm-4 {


                padding-left: 49%;

                @media(max-width:768px) {
                    padding-left: 35%;
                }

                @media(max-width:400px) {
                    padding-left: 26%;
                }

                :nth-child(2) {
                    left: 75%;

                }
            }

        }

        .bottom-container {

            margin-top: 168px;
            margin-bottom: 108px;
            display: flex;
            align-items: center;

            @media(max-width:768px) {
                margin-top: 40px;
                margin-bottom: 42px;
                width: 100vw;

                img {
                    width: 88px;
                    height: 87.71px;
                }
            }

            .bottom-container-line {
                width: 100%;
                left: 45.67px;

                @media(max-width: 768px) {
                    left: 0%;

                }
            }
        }





    }
}

.blue-print-card {
    min-width: 437px;
    margin-top: 13px;
    background: #050505;
    border: 1px solid #FFFFFF;
    z-index: 100;
    position: relative;

    @media(max-width:768px) {
        min-width: 342px;
    }

    @media(max-width:400px) {
        min-width: 300px;
    }


    img {
        position: absolute;
        right: 8px;
        top: 8px;
    }

    .blue-print-card-main-container {


        padding: 31px 24px;

        .blue-print-card-heading {
            font-family: 'Code Next', sans-serif;
            font-style: normal;
            font-weight: 800;
            font-size: 24px;
            line-height: 35px;
            color: #FBD660;
            margin-bottom: 12px;

            @media(max-width: 1200px) {
                font-weight: 700;
                font-size: 24px;
                line-height: 30px;
            }

        }

        .blue-print-card-para {
            font-family: 'Euclid Circular A', sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            line-height: 150%;
            color: #BDBBBB;

            @media(max-width: 1200px) {
                font-weight: 500;
                font-size: 12px;
                line-height: 150%;
            }

        }

    }
}