.swiper {
    width: 100%;
    // height: 100%;
    margin-top: 60px;
}



.swiper-slide {
    .image-wrapper {
        margin: 0 auto;

        img {
            margin: 0 auto;
            border: 0.869223px solid #707070;
            padding: 10px;
            width: 252.94px;
            height: 240.77px;
            object-fit: contain;
        }
    }
}

.swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
}