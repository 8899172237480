.blog-page-container {

    margin-top: 104px;

    color: #fff;
    position: relative;
    min-height: 100vh;
    padding-top: 54px;
    padding-left: 5.2%;
    padding-right: 5.2%;
    margin-left: auto;
    margin-right: auto;
    @media(max-width:768px) {
        min-height: unset;
    }

    .blog-page-content {
        max-width: 1024px;
        margin: 0px auto;
        .section-heading {
            font-family: 'Primal';
            font-style: normal;
            font-weight: 400;
            font-size: 24px;
            line-height: 175%;
            /* identical to box height, or 42px */

            text-align: justify;
            letter-spacing: 0.02em;

            color: #707070;
        }

        .blog-section-heading {
            font-family: 'Primal';
            font-style: normal;
            font-weight: 400;
            font-size: 24px;
            line-height: 175%;
            /* identical to box height, or 42px */

            text-align: justify;
            letter-spacing: 0.02em;
            text-transform: uppercase;
            color: #FFFFFF;

            margin-top: 24px;

        }

        .featured-blog {
            margin-top: 24px;
            margin-bottom: 48px;
        }

        .blog-list {

            display: grid;
            grid-template-columns: repeat(auto-fit, minmax(326px, 1fr));

            column-gap: 23px;
            row-gap: 24px;
            align-items: stretch;

            a:nth-child(1) {
                display: block;

                @media(min-width:768px) {
                    // hide first element of list above 768px as it shows in featured
                    display: none;
                    border: 20px solid red;
                }
            }
        }



        .read-more-container {
            position: relative;
            display: flex;
            justify-content: center;
            margin-top: 40px;
            margin-bottom: 160px;

            .seperator {
                position: absolute;
                top: 22.5px;
                height: 1px;
                width: calc(50% - 7px - 70px);
                background-color: #707070;

                &.left {
                    left: 0;
                }

                &.right {
                    right: 0;
                }
            }

            .btn-primary {
                cursor: pointer;
                position: relative;
                z-index: 2;
                height: 45px;

                background: #242424;
                border: 1px solid rgba(255, 255, 255, 0.1);
                border-radius: 12px;

                font-family: 'Code Next';
                font-style: normal;
                font-weight: 800;
                font-size: 14px;
                line-height: 21px;
                padding: 12px 24px;
                /* identical to box height */

                color: #FFFFFF;
            }
        }
    }
}