.anchor-blog-card{
    text-decoration: none;
}
.blog-card {
    width: 100%;

    &.featured { // this hides on screen width below 768px
        @media(max-width:768px) {
            display: none;
        }
        .blog-image {
            aspect-ratio: calc(1327/423);
        }

        .blog-info {
            padding: 58px 32px;
            display: grid;
            grid-template-columns: 0.57fr 0.43fr;
            column-gap: 31px;
            align-items: center;

            .date-seperator {
                margin-top: 48px;
                margin-bottom: 16px;
            }

            .btn-primary {
                margin-top: 12px;
                cursor: pointer;
                position: relative;
                z-index: 2;
                height: 45px;

                background: #242424;
                border: 1px solid rgba(255, 255, 255, 0.1);
                border-radius: 12px;

                font-family: 'Code Next';
                font-style: normal;
                font-weight: 800;
                font-size: 14px;
                line-height: 21px;
                padding: 12px 24px;
                /* identical to box height */

                color: #FFFFFF;
            }
        }
    }

    .blog-image {
        aspect-ratio: calc(435/338);
        border-radius: 56px 0px 0px 0px;
        width: 100%;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: inherit;
        }
    }

    .blog-info {
        margin-top: 12px;
        border: 1px solid rgba(112, 112, 112, 0.3);
        border-radius: 0px 0px 40px 0px;
        width: 100%;
        min-height: 224px;
        padding: 20px;
        background: #121212;

        .heading {
            height: 64px;

            font-family: 'Euclid Circular A';
            font-style: normal;
            font-weight: 500;
            font-size: 24px;
            line-height: 30px;
            letter-spacing: 0.02em;

            color: #FFFFFF;

            -webkit-box-orient: vertical;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: normal;

        }

        .date {
            font-family: 'Euclid Circular A';
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 20px;
            text-align: justify;
            letter-spacing: 0.02em;

            color: #FFFFFF;

            opacity: 0.7;
            margin-top: 12px;

            @media(max-width:768px) {
                margin-bottom: 8px;
            }
        }

        .date-seperator {
            height: 1px;
            width: 100%;
            background-color: #707070;
            margin: 12px 0px;

            &.mobile{
                display: none;
                @media(max-width:768px) {
                    display: block;
                }
            }
            &.desktop{
                display: block;
                @media(max-width:768px) {
                    display: none;
                }
            }
        }

        .description {
            height: 60px;

            font-family: 'Euclid Circular A';
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 20px;
            text-align: justify;
            letter-spacing: 0.02em;

            color: #FFFFFF;

            opacity: 0.7;

            -webkit-box-orient: vertical;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: normal;
        }
    }
}