.terms-container {
    margin-top: 100px;
    position: relative;

    .terms-header {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding-top: 10px;
        padding-bottom: 10px;

        .heading {
            font-family: 'Stargaze';
            font-style: normal;
            font-weight: 400;
            font-size: 72px;
            line-height: 95px;
            color: #FFFFFF;

            // tab media query
            @media (min-width: 768px) {
                font-size: 60px;
                line-height: 80px;
                text-align: center;
            }

            // mobile media query
            @media (max-width: 568px) {
                font-weight: 400;
                font-size: 24px;
                line-height: 32px;
            }
        }

        .desc {
            font-family: 'Primal';
            font-style: normal;
            font-weight: 400;
            font-size: 24px;
            line-height: 28px;

            letter-spacing: 0.25em;

            color: #FFFFFF;
            padding-top: 10px;

            // mobile media query
            @media (max-width: 568px) {
                font-weight: 400;
                font-size: 14px;
                line-height: 16px;
            }


        }


    }

    .divider-line {
        width: 100%;
        height: 1px;
        border: 1px solid #707070;
        opacity: 0.3;
        margin-top: 20px;
        margin-bottom: 20px;

    }

    .vertical-right-line {
        position: absolute;
        right: 0;
        top: 0;
        width: 1px;
        height: 100%;
        border: 1px solid #707070;

    }

    .terms-content-wrapper {
        max-width: 1000px;
        margin: 0 auto;
        padding-top: 20px;
        padding-bottom: 20px;
        padding-left: 10px;
        padding-right: 10px;
        color: #BDBBBB;

        .terms-intro {
            p {
                font-family: 'Euclid Circular A', sans-serif;
                font-style: normal;
                font-weight: 400;
                font-size: 18px;
                line-height: 175%;
                /* or 32px */

                text-align: center;

                color: #BDBBBB;
                margin-bottom: 28px;

                // mobile media query
                @media (max-width: 568px) {
                    font-style: normal;
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 175%;
                }
            }
        }

        .terms-points {
            .point-heading {
                color: #ffff;
                font-family: 'Euclid Circular A', sans-serif;
                font-weight: 400;
                font-size: 18px;
                line-height: 175%;
                text-align: center;

            }

            .point-desc {
                font-family: 'Euclid Circular A', sans-serif;
                font-style: normal;
                font-weight: 400;
                font-size: 18px;
                line-height: 175%;
                /* or 32px */

                text-align: center;

                color: #BDBBBB;
                margin-bottom: 28px;

                // mobile media query
                @media (max-width: 568px) {
                    font-style: normal;
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 175%;
                }
            }
        }
    }
}