.input-number {
    height: 32px;
    border-radius: 2px;


    display: flex;

    &:hover {
        border-color: red;
    }

    .input-container {
        border: 1px solid #FDF2CE;
        height: 50px;
        padding-left: 5px;
        padding-right: 5px;
        display: flex;
        align-items: center;
        margin-right: 10px;
        @media(max-width:768px) {
            height: 40px;
    
        }

        .input-text {
            outline: none;
            border: none;
            padding-left: 10px;
            padding-right: 10px;
            width: inherit;


        }
    }

    .arrow-container {
        border: 1px solid #FDF2CE;
        display: flex;
        flex-direction: column;
        border-left: 1px solid #FDF2CE;
        height: 50px;
        margin-left: 5px;
        margin-right: 5px;
        
    @media(max-width:768px) {
        height: 40px;

    }

        &:hover {
            border-color: gray;
        }

        .img-wrapper-1 {
            height: 50%;
            // padding-left: 5px;

            display: flex;
            align-items: center;
            justify-content: center;
            padding: 2px 7px ;
            cursor: pointer;

            // border-bottom: 1px solid #FDF2CE;
            img {
                // margin-bottom: 10px;
                // transform: rotate(180deg);
            }

        }

        .img-wrapper-2 {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 50%;

            cursor: pointer;

            img {
                margin-top: 5px;
                margin-bottom: 10px;
                // transform: rotate(180deg);
            }


        }
    }
}