.join-community-section {

    background-image: url("../../assets/images/join-community-bg.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-color: #fff;

    // mobile media query
    @media (max-width: 576px) {
        background-image: url("../../assets/images/join-culture-mobile.png");
    }






    // padding: 100px 0;

    .join-community-container {

        // max-width: 1300px;
        padding-left: 40px;
        padding-right: 40px;
        padding-top: 40px;
        padding-bottom: 100px;

        // mobile media query
        @media (max-width: 768px) {
            padding-left: 20px;
            padding-right: 20px;
            padding-top: 40px;
            padding-bottom: 50px;
        }

        margin: 0 auto;

        .join-community-inner-wrapper {
            // height: 80vh;
            // display: flex;
            // justify-content: space-around;

            // align-items: center;
            display: grid;
            grid-template-columns: 1fr 1fr;
            place-items: center;
            grid-gap: 20px;
            place-content: center;

            padding-top: 50px;
            padding-bottom: 50px;
            color: var(--color-white);

            // tablet media query
            @media (max-width: 768px) {
                grid-template-columns: 1fr;
                grid-gap: 0px;
                place-content: center;
                padding-top: 0px;
                padding-bottom: 0px;

            }

            .left-container {
                .join-community-title {
                    h1 {
                        font-family: 'Stargaze';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 56px;
                        line-height: 74px;
                        color: #FBD660;

                        // tablet media query
                        @media (max-width: 768px) {
                            font-size: 44px;
                            line-height: 60px;
                        }

                        // mobile media query
                        @media (max-width: 576px) {
                            font-size: 32px;
                            line-height: 37px;
                        }
                    }

                    p {
                        font-family: 'Primal';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 24px;
                        line-height: 28px;
                        padding-top: 20px;
                        padding-bottom: 20px;
                        letter-spacing: 0.25em;
                        color: #FFFFFF;


                        // tablet media query
                        @media (max-width: 768px) {
                            font-size: 20px;
                            line-height: 24px;
                            text-align: center;
                        }

                        // mobile media query
                        @media (max-width: 576px) {
                            font-weight: 400;
                            font-size: 14px;
                            line-height: 16px;
                            text-align: center;
                        }
                    }
                }

                .join-cta {
                    display: flex;
                    margin-top: 30px;
                    margin-left: 48px;

                    // laptop media query
                    @media (max-width: 1024px) {
                        margin-left: 0px;
                    }


                    // tablet media query
                    @media (max-width: 768px) {
                        margin-bottom: 30px;
                        // flex-direction: column;
                    }

                    // mobile media query
                    @media (max-width: 576px) {
                        display: none;
                    }



                    .twitter-btn {
                        border: 1px solid #FDF2CE;
                        position: relative;

                        padding: 15px 45px;
                        cursor: pointer;

                        // mobile media query
                        @media (max-width: 576px) {
                            padding: 10px 30px;
                        }

                        span {
                            font-family: 'Code Next', sans-serif;
                            font-style: normal;
                            font-weight: 800;
                            font-size: 18px;
                            line-height: 26px;
                            color: #FDF2CE;

                            // mobile media query
                            @media (max-width: 576px) {
                                font-size: 14px;
                                line-height: 16px;
                            }

                        }

                        .twitter-btn-line-1 {
                            position: absolute;
                            left: -49px;
                            bottom: 9px;
                            width: 48%;
                            height: 1px;
                            background-color: #FDF2CE;
                            transform: rotate(-90deg);
                            -webkit-transform: rotate(-90deg);
                            -moz-transform: rotate(-90deg);
                            -ms-transform: rotate(-90deg);
                            -o-transform: rotate(-90deg);
                            cursor: pointer;

                            // mobile media query
                            @media (max-width: 576px) {
                                left: -39px;
                                bottom: -1px;
                                width: 43%;
                            }
                        }

                        .twitter-btn-line-2 {
                            position: absolute;
                            left: -49px;
                            bottom: -11px;
                            width: 66%;
                            height: 1px;
                            background-color: #FDF2CE;
                            cursor: pointer;

                            // mobile media query
                            @media (max-width: 576px) {
                                left: -36px;
                                bottom: -11px;
                                width: 45%;
                            }
                        }
                    }

                    .Discord-btn {
                        background: #FBD660;
                        padding: 15px 50px;
                        position: relative;
                        margin-left: 60px;
                        cursor: pointer;

                        // tablet media query
                        @media (max-width: 768px) {
                            margin-left: 30px;
                        }


                        // mobile media query
                        @media (max-width: 576px) {
                            padding: 10px 30px;
                        }

                        span {
                            font-family: 'Code Next', sans-serif;
                            // font-style: normal;
                            font-weight: 800;
                            font-size: 18px;
                            line-height: 26px;
                            color: #111111;

                            // mobile media query
                            @media (max-width: 576px) {
                                font-size: 14px;
                                line-height: 16px;
                            }



                        }

                        // top right lines
                        .discord-btn-line-top-right-1 {
                            position: absolute;
                            right: -49px;
                            top: -11px;
                            width: 66%;
                            height: 1px;
                            background-color: #FDF2CE;
                            cursor: pointer;

                            // mobile media query
                            @media (max-width: 576px) {
                                right: -30px;
                                top: -11px;
                                width: 60%;
                            }

                        }

                        .discord-btn-line-top-right-2 {
                            position: absolute;
                            right: -49px;
                            top: 9px;
                            width: 48%;
                            height: 1px;
                            background-color: #FDF2CE;
                            cursor: pointer;
                            transform: rotate(90deg);
                            -webkit-transform: rotate(90deg);
                            -moz-transform: rotate(90deg);
                            -ms-transform: rotate(90deg);
                            -o-transform: rotate(90deg);

                            // mobile media query
                            @media (max-width: 576px) {
                                right: -34px;
                                top: -1px;
                                width: 45%;
                            }


                        }


                        // bottom  left line

                        .discord-btn-line-1 {
                            position: absolute;
                            left: -53px;
                            bottom: 0px;
                            width: 49%;
                            height: 1px;
                            cursor: pointer;
                            background-color: #FBD660;
                            transform: rotate(-90deg);
                            -webkit-transform: rotate(-90deg);
                            -moz-transform: rotate(-90deg);
                            -ms-transform: rotate(-90deg);
                            -o-transform: rotate(-90deg);

                            // mobile media query
                            @media (max-width: 576px) {
                                left: -32px;
                                bottom: 0px px;
                                width: 40%;
                            }
                        }

                        .discord-btn-line-2 {
                            position: absolute;
                            left: -35px;
                            bottom: -13px;
                            width: 49%;
                            height: 1px;
                            background-color: #FBD660;
                            cursor: pointer;

                            // mobile media query
                            @media (max-width: 576px) {
                                left: -26px;
                                bottom: -12px;
                                width: 49%;
                            }
                        }
                    }
                }
            }

            .right-container {
                .text-content-wrapper-1 {
                    border: 1px solid #707070;
                    margin-bottom: 50px;
                    width: 600px;
                    // margin-right: 30px;

                    // laptop media query
                    @media (max-width: 1024px) {
                        width: 449px;
                    }

                    // tablet media query
                    @media (max-width: 768px) {
                        margin-top: 20px;
                        margin: 20px auto;
                        width: 90%;

                    }

                    @media (max-width: 576px) {
                        width: 100%;
                    }

                    p {
                        font-family: 'Euclid Circular A', sans-serif;

                        font-style: normal;
                        font-weight: 500;
                        font-size: 18px;
                        line-height: 150%;
                        /* or 27px */
                        padding: 40px;
                        color: #BDBBBB;

                        // laptop media query
                        @media (max-width: 1024px) {

                            padding: 30px;
                        }

                        // tablet media  query
                        @media (max-width: 768px) {
                            padding: 20px;
                        }

                        // mobile media query
                        @media (max-width: 576px) {
                            // padding: 10px;
                            font-weight: 400;
                            font-size: 16px;
                            line-height: 150%;
                        }
                    }
                }

                .text-content-wrapper-2 {
                    border: 1px solid #707070;
                    margin-top: 40px;
                    width: 600px;

                    // laptop media query
                    @media (max-width: 1024px) {
                        width: 449px;
                    }

                    // tablet media query
                    @media (max-width: 768px) {
                        margin: 20px auto;

                        width: 90%;

                    }

                    // mobile media query
                    @media (max-width: 576px) {
                        width: 100%;
                    }

                    p {
                        font-family: 'Euclid Circular A', sans-serif;

                        font-style: normal;
                        font-weight: 500;
                        font-size: 18px;
                        line-height: 150%;
                        /* or 27px */
                        padding: 40px;
                        color: #BDBBBB;


                        // mobile media query
                        @media (max-width: 576px) {
                            // padding: 10px;
                            font-weight: 400;
                            font-size: 16px;
                            line-height: 150%;
                        }


                        span {
                            font-family: 'Primal', sans-serif;
                            display: block;
                            color: var(--color-white);
                            padding-bottom: 3px;
                            font-size: 22px;

                            color: #FBD660;
                        }
                    }
                }
            }

            .mint-now-btn {
                background: #FBD660;
                padding: 15px 50px;
                position: relative;
                // margin-left: 60px;
                cursor: pointer;
                display: none;
                margin-top: 40px;
                // box-shadow: 0px 0px 32px 5px rgba(251, 249, 240, 0.4), inset 0px 53px 24px rgba(255, 253, 253, 0.1);




                // mobile media query
                @media (max-width: 576px) {
                    display: block;
                }

                span {
                    font-family: 'Code Next', sans-serif;
                    // font-style: normal;
                    font-weight: 800;
                    font-size: 18px;
                    line-height: 26px;
                    color: #111111;




                }

                // top right lines
                .mint-btn-line-top-right-1 {
                    position: absolute;
                    right: -49px;
                    top: -11px;
                    width: 63%;
                    height: 1px;
                    background-color: #FDF2CE;
                    cursor: pointer;



                }

                .mint-btn-line-top-right-2 {
                    position: absolute;
                    right: -49px;
                    top: 9px;
                    width: 43%;
                    height: 1px;
                    background-color: #FDF2CE;
                    cursor: pointer;
                    transform: rotate(90deg);
                    -webkit-transform: rotate(90deg);
                    -moz-transform: rotate(90deg);
                    -ms-transform: rotate(90deg);
                    -o-transform: rotate(90deg);




                }


                // bottom  left line

                .mint-btn-line-1 {
                    position: absolute;
                    left: -53px;
                    bottom: 0px;
                    width: 44%;
                    height: 1px;
                    cursor: pointer;
                    background-color: #FDF2CE;
                    transform: rotate(-90deg);
                    -webkit-transform: rotate(-90deg);
                    -moz-transform: rotate(-90deg);
                    -ms-transform: rotate(-90deg);
                    -o-transform: rotate(-90deg);


                }

                .mint-btn-line-2 {
                    position: absolute;
                    left: -35px;
                    bottom: -13px;
                    width: 45%;
                    height: 1px;
                    background-color: #FDF2CE;

                    cursor: pointer;


                }
            }
        }
    }
}