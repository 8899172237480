.mint-page-container {

    margin-top: 104px;

    background: url('../../assets/images/Mint-page-bg.png') no-repeat center center/cover;
    color: #fff;
    position: relative;
    min-height: 100vh;
    border: 0.1px solid transparent;

    @media(max-width:768px) {
        min-height: unset;
        margin-top: 0px;
        padding-top: 57px;
    }


    .mintPage-inner-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: inherit;
        padding: 0px;
        gap: 16px;
        position: relative;
        // padding-bottom: clamp(10px, 10%, 248px);
        padding-top: 40px;

        @media(max-width:768px) {}

        .mintPage-heading {
            font-size: 112px;
            line-height: 105px;
            color: rgba(255, 255, 255, 1);
            font-family: 'Codevein', sans-serif;
            text-shadow: 0px 0px 32px rgba(251, 249, 240, 0.4);


            span {
                color: #FBD660;

            }

            @media(max-width: 1024px) {

                font-size: 90px;
                line-height: 90px;
            }

            @media(max-width: 768px) {

                font-size: 50px;
                line-height: 37px;
            }

            @media(max-width: 576px) {
                font-size: 40px;
                line-height: 37px;
            }
        }

        .mintPage-subheading {
            font-size: 32px;
            line-height: 37px;
            letter-spacing: 0.25em;
            font-family: 'Primal';
            color: #FFFFFF;
            text-align: center;

            span {
                color: #FBD660;

            }

            @media(max-width: 1024px) {

                font-size: 27px;
                line-height: 30px;
            }



            @media(max-width: 768px) {
                font-size: 14px;
                line-height: 16px;
            }

            @media(max-width: 568px) {
                max-width: 300px;

            }
        }

        .sales-text {
            margin-top: 20px;
            margin-bottom: 20px;
            display: flex;
            align-items: center;


            // mobile media query
            @media(max-width: 568px) {
                flex-direction: column;
                align-items: center;
                justify-content: center;
                margin-bottom: 0px;

            }

            .leftSide-text {
                // border: 1px dashed #707070;
                background-image: repeating-linear-gradient(0deg, #707070, #707070 9px, transparent 9px, transparent 21px, #707070 21px), repeating-linear-gradient(90deg, #707070, #707070 9px, transparent 9px, transparent 21px, #707070 21px), repeating-linear-gradient(180deg, #707070, #707070 9px, transparent 9px, transparent 21px, #707070 21px), repeating-linear-gradient(270deg, #707070, #707070 9px, transparent 9px, transparent 21px, #707070 21px);
                background-size: 1px 100%, 100% 1px, 1px 100%, 100% 1px;
                background-position: 0 0, 0 0, 100% 0, 0 100%;
                background-repeat: no-repeat;

                padding: 24px;

                @media(max-width: 1024px) {
                    padding: 21px;

                }

                @media(max-width: 768px) {
                    padding: 15px;
                }

                // mobile media query
                @media(max-width: 568px) {
                    margin-bottom: 20px;
                }


                .text-wrapper {

                    display: flex;

                    h5 {
                        font-family: 'Euclid Circular A', sans-serif;

                        font-style: normal;
                        font-weight: 500;
                        font-size: 24px;
                        line-height: 30px;
                        letter-spacing: 0.05em;
                        padding-left: 10px;
                        /* White */

                        color: #FFFFFF;

                        span {
                            color: #FBD660;
                        }

                        @media(max-width: 1024px) {
                            font-size: 21px;
                        }

                        @media(max-width: 768px) {
                            font-size: 15px;
                            line-height: 16px;
                        }
                    }
                }
            }

            .rightSide-text {
                display: flex;
                align-items: center;
                margin-left: 20px;

                @media(max-width: 568px) {
                    margin-left: 0px;
                    margin-bottom: 13px;
                }

                h5 {
                    font-family: 'Euclid Circular A', sans-serif;

                    font-style: normal;
                    font-weight: 500;
                    font-size: 24px;
                    line-height: 30px;
                    letter-spacing: 0.05em;
                    padding-left: 10px;
                    /* White */

                    color: #FFFFFF;

                    // tab media query
                    @media(max-width: 1024px) {
                        font-size: 16px;
                        line-height: 16px;
                    }

                    @media(max-width: 768px) {
                        font-size: 15px;
                        line-height: 16px;
                    }
                }
            }
        }

        .mint-buttons {
            display: flex;
            align-items: center;
            margin-top: 40px;
            position: relative;

            // mobile media query
            @media(max-width: 568px) {
                flex-direction: column;
                align-items: center;
                justify-content: center;
                margin-top: 0px;
                margin-bottom: 20px;

            }


            .number-incrementor {
                height: 50px;

                .input-btn-line-1 {
                    position: absolute;
                    bottom: -6px;
                    border: 1px solid #FDF2CE;
                    left: -26px;
                    height: 1px;
                    width: 14%;
                    display: block;

                    @media(max-width: 568px) {
                        display: none;

                    }
                }

                .input-btn-line-2 {
                    position: absolute;
                    bottom: 6px;
                    border: 1px solid #FDF2CE;
                    left: -40px;
                    transform: rotate(-90deg);
                    height: 1px;
                    width: 15%;
                    display: block;

                    @media(max-width: 568px) {
                        display: none;

                    }
                }

            }

            .mint-now-btn {
                background: #FBD660;
                padding: 15px 50px;
                position: relative;
                margin-left: 40px;
                cursor: pointer;
                // display: none;
                // box-shadow: 0px 0px 32px 5px rgba(251, 249, 240, 0.4), inset 0px 53px 24px rgba(255, 253, 253, 0.1);


                @media(max-width: 768px) {
                    padding: 10px 30px;
                }

                // mobile media query
                @media (max-width: 576px) {
                    display: block;
                    margin-top: 16px;
                    margin-left: 0;
                }

                span {
                    font-family: 'Code Next', sans-serif;
                    // font-style: normal;
                    font-weight: 800;
                    font-size: 18px;
                    line-height: 26px;
                    color: #111111;

                    @media(max-width: 768px) {
                        font-size: 14px;
                        line-height: 16px;
                    }




                }

                // top right lines
                .mint-btn-line-top-right-1 {
                    position: absolute;
                    right: -38px;
                    top: -11px;
                    width: 41%;
                    height: 1px;
                    background-color: #FDF2CE;
                    cursor: pointer;

                    @media(max-width: 768px) {
                        right: -31px;
                        top: -11px;
                        width: 40%;

                    }
                }

                .mint-btn-line-top-right-2 {
                    position: absolute;
                    right: -50px;
                    top: 9px;
                    width: 31%;
                    height: 1px;
                    background-color: #FDF2CE;
                    cursor: pointer;
                    transform: rotate(90deg);
                    -webkit-transform: rotate(90deg);
                    -moz-transform: rotate(90deg);
                    -ms-transform: rotate(90deg);
                    -o-transform: rotate(90deg);

                    @media(max-width: 768px) {
                        right: -40px;
                        top: 3px;
                        width: 30%;
                        height: 1px;

                    }




                }


                // bottom  left line

                .mint-btn-line-1 {
                    position: absolute;
                    left: -43px;
                    bottom: 0px;
                    width: 27%;
                    height: 1px;
                    cursor: pointer;
                    background-color: #FDF2CE;
                    transform: rotate(-90deg);
                    -webkit-transform: rotate(-90deg);
                    -moz-transform: rotate(-90deg);
                    -ms-transform: rotate(-90deg);
                    -o-transform: rotate(-90deg);

                    @media(max-width: 768px) {
                        left: -34px;
                    }

                }

                .mint-btn-line-2 {
                    position: absolute;
                    left: -35px;
                    bottom: -13px;
                    width: 34%;
                    height: 1px;
                    background-color: #FDF2CE;
                    cursor: pointer;

                    @media(max-width: 768px) {
                        left: -30px;
                        bottom: -12px;
                    }


                }
            }
        }
    }
}